<template>
  <div class="container">
    <van-nav-bar title="预约安装登记" />
    <div class="success-img">
      <img src="../../assets/images/install_success.png" alt="" />
      <div>预约成功</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.container {
  position: relative;
}
.success-img {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 17px;
  color: #ffffff;
  text-align: center;
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
}
</style>
